.Settings {
    position: fixed;
    bottom: 5vh;
    right: calc(5vw);
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.75);
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin-left: 5vw;
    max-width: 90vw;
    transform: translate(0, 0);
    box-sizing: border-box;
    max-height: 90vh;
    overflow-y: auto;
}

/* FIREFOX */
@-moz-document url-prefix() {
    .Settings {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
    }
}

/* CHROME */
.Settings::-webkit-scrollbar {
    width: 0.5rem;
    padding-top: 20px;
}

.Settings::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Settings::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 30px;
}

.SettingsHeader {
    padding-bottom: 1rem;
}

#hueSelector{
    padding: 20px 0;
}

.SettingsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SettingsDescription {
    flex: 1 1 55%;
    min-width: fit-content;
}

.SettingsSlider {
    flex: 1 1 45%;
    max-width: 100%;
    margin-top: -10px;
}

.SettingsRowButton {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.SettingsButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.SettingsInformation {
    margin-top: 1em;
}

@media (max-width: 768px) {
    .SettingsRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .SettingsDescription, .SettingsSlider {
        width: 100%;
    }
}
